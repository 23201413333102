<template>
  <div>
    <div class="text-center" v-if="!departmentDataLoaded || !employeesDataLoaded">
      <v-progress-circular class="mt-6" :size="50" color="primary" indeterminate />
    </div>

    <div class="mx-4" v-if="departmentDataLoaded">
      <div v-show="employeesDataLoaded">
        <PageTitle title="Сотрудники кафедры" />

        <div ref="infoBlocks">
          <v-row class="my-0">
            <v-col cols="12" class="pt-0 pb-5">
              <h4 class="department">{{ departmentName }}</h4>
            </v-col>
          </v-row>

          <v-row class="my-0">
            <v-col cols="12" class="py-0">
              <v-card class="base-card pa-5">
                <v-row class="my-0">
                  <v-col cols="12" class="py-0">
                    <div>
                      <v-btn small outlined color="primary" class="white--text px-4" to="/employees/load">
                        <v-icon left size="18">mdi-clock-time-nine-outline</v-icon>Нагрузка преподавателей
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <EmployeesTable
          :infoBlocksHeight="infoBlocksHeight"
          @dataLoaded="setEmployeesDataLoaded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageTitle from '@/components/ui/Title'
import EmployeesTable from '@/components/employess/employees/Table'

export default {
  name: 'Employees',

  metaInfo: {
    title: 'Сотрудники кафедры'
  },

  components: {
    PageTitle,
    EmployeesTable
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    this.departmentDataLoaded = true
  },

  mounted() {
    this.hideHtmlOverflow()
    window.addEventListener("resize", this.setInfoBlocksHeight)
  },

  destroyed() {
    this.showHtmlOverflow()
    window.removeEventListener("resize", this.setInfoBlocksHeight)
  },

  data: () => ({
    departmentDataLoaded: false,
    employeesDataLoaded: false,
    infoBlocksHeight: 0
  }),

  computed: {
    ...mapGetters('department', ['departmentName'])
  },

  methods: {
    setEmployeesDataLoaded() {
      this.employeesDataLoaded = true
      this.setInfoBlocksHeight()
    },

    setInfoBlocksHeight() {
      this.$nextTick(() => {
        const infoBlocks = this.$refs.infoBlocks

        if (infoBlocks) {
          this.infoBlocksHeight = infoBlocks.offsetHeight
        }
      })
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>