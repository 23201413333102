<template>
  <div>
    <div v-show="dataLoaded">
      <v-row class="my-0">
        <v-col cols="12" class="py-0">
          <v-card class="table-card mt-4">
            <v-card-title class="py-3">
              <span class="accent--text">Сотрудники</span>
              <v-spacer></v-spacer>

              <v-text-field
                v-model="tableSearch"
                class="mt-0 pt-0 table-search"
                append-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
                clearable
              />
            </v-card-title>

            <v-data-table
              ref="employeesTable"
              class="base-table"
              fixed-header
              hide-default-footer
              :headers="headers"
              :items="employees"
              :search="tableSearch"
              :items-per-page="-1"
            >
              <template slot="no-data">
                <div class="py-3">Нет сотрудников на данной кафедре</div>
              </template>

              <template slot="no-results">
                <div class="py-3">Ничего не найдено</div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { departmentApi } from '@/api'
import { mapGetters } from 'vuex'

export default {
  name: 'EmployeesTable',

  props: {
    infoBlocksHeight: Number
  },

  async created() {
    await this.getEmployeesList()
    this.dataLoaded = true
    this.$emit('dataLoaded')
    this.setTableHeight()
  },

  mounted() {
    window.addEventListener("resize", this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight)
  },

  data: () => ({
    dataLoaded: false,
    tableSearch: null,
    headers: [
      { text: 'Номер', value: 'emplNumb', sortable: false, align: 'left' },
      { text: 'ФИО', value: 'fullName', sortable: true, align: 'left' },
      { text: 'Должность', value: 'emplTitleCode', sortable: true, align: 'left' },
      { text: 'Тип', value: 'emplWorkType', sortable: true, align: 'left' }
    ],
    employees: []
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('department', ['departmentId'])
  },

  methods: {
    setTableHeight() {
      this.$nextTick(() => {
        if (this.$refs?.employeesTable) {
          const otherElements = 292 + this.infoBlocksHeight + this.techMessageHeight
          this.$refs.employeesTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
        }
      })
    },

    async getEmployeesList() {
      try {
        this.employees = await departmentApi.getEmployeesListByDepartmentId(this.departmentId)
      } catch (e) {
        console.log(e.status)
      }
    },
  },

  watch: {
    infoBlocksHeight() {
      this.setTableHeight()
    }
  }
}
</script>